<template>
  <div class="regView">
    <van-sticky>
      <van-nav-bar left-arrow @click-left="onClickLeft" :right-text="$t('选择语言')" @click-right="onClickRight"></van-nav-bar>
    </van-sticky>
    <div class="flool loginsignup2">
      <div class="l_1">{{ $t('欢迎注册') }}</div>
    </div>
    <div class="login_box text-center">
      <van-cell-group>
        <van-row class="mobile">
          <van-field left-icon="manager">
            <template #label>
              <van-dropdown-menu active-color="#ffffff">
                <van-dropdown-item
                  v-model="value1"
                  :title="dropTitle"
                  :options="option1"
                  @change="onChangeArea"
                />
              </van-dropdown-menu>
            </template>
            <template #input>
              <input v-model="mobile" :placeholder="$t('请输入手机号码')" class="van-field__control" />
            </template>
          </van-field>
        </van-row>
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="password"
          left-icon="lock"
          type="password"
          right-icon="closed-eye"
          :placeholder="$t('请输入登录密码')"
        />
      </van-cell-group>
      <van-cell-group>
        <van-field
          v-model="repassword"
          left-icon="lock"
          type="password"
          right-icon="closed-eye"
          :placeholder="$t('请输入确认登录密码')"
        />
      </van-cell-group>

    <!-- <van-cell-group>
        <van-field v-model="verifyCode" left-icon="bell" :placeholder="$t('请输入验证码')">
          <template #button>
            <van-image width="100" :src="verifyImage" @click="changeVerify" />
          </template>
        </van-field>
      </van-cell-group> -->
      <van-cell-group>
        <van-field v-model="inviteParent" left-icon="invition" :placeholder="$t('请输入邀请码')" />
      </van-cell-group>

      <van-button type="info" class="big_other_btn" @click="submitHandler">{{ $t('立即注册') }}</van-button>
      <!-- <van-button type="info" plain class="big_btn" @click="downHandler">{{ $t('已有账号，马上下载') }}</van-button> -->
    </div>

    <van-popup v-model="showDragVerifyImgRotate">
      <drag-verify-img-rotate
        ref="dragVerify"
        :imgsrc="img"
        :isPassing.sync="isPassing"
        :showTips="true"
        :text="$t('请按住滑块拖动')"
        :successText="$t('验证通过')"
        :failTip="$t('验证失败')"
        handlerIcon="el-icon-d-arrow-right"
        successIcon="el-icon-circle-check"
        @loosen="onLoosen"
      ></drag-verify-img-rotate>
    </van-popup>
  </div>
</template>
<script>
import { saveToLocal, loadFromLocal } from "@/utils/local-storage";
import { getDownUrl, getCheckImage } from "@/api/utils";

// 旋转图片滑块组件
import dragVerifyImgRotate from "@/components/dragVerifyImgRotate";
export default {
  name: "login",
  components: {
    dragVerifyImgRotate
  },
  data() {
    return {
      showDragVerifyImgRotate: false,
      isPassing: false,
      img: "",
      inviteParent: this.$route.query.code,
      mobile: "",
      password: "",
      repassword: "",
      verifyCode: "",
      verifyUlr: this.$imageUrl + "/api/login/verify",
      verifyImage: "",
      value1: 7,
      dropTitle: "+81",
      app_download_url: "",
      uuid: "",
      option1: [
        { text: "+66 "+this.$t('泰国'), value: 0 },
        { text: "+60 "+this.$t('马来西亚'), value: 1 },
        { text: "+91 "+this.$t('印度'), value: 2 },
        { text: "+86 "+this.$t('中国'), value: 3 },
        { text: "+63 "+this.$t('菲律宾'), value: 4 },
        { text: "+62 "+this.$t('印度尼西亚'), value: 5 },
        { text: "+65 "+this.$t('新加坡'), value: 6 },
        { text: "+81 "+this.$t('日本'), value: 7 },
        { text: "+82 "+this.$t('韩国'), value: 8 },
        { text: "+84 "+this.$t('越南'), value: 9 },
        { text: "+852 "+this.$t('香港'), value: 10 },
        { text: "+853 "+this.$t('澳门'), value: 11 },
        { text: "+855 "+this.$t('柬埔寨'), value: 12 },
        { text: "+856 "+this.$t('老挝'), value: 13 },
        { text: "+886 "+this.$t('台湾'), value: 14 },
        { text: "+95 "+this.$t('缅甸'), value: 15 },
        { text: "+960 "+this.$t('马尔代夫'), value: 16 }
      ]
    };
  },
  created() {
    this.GetDownUrl();
  },
  mounted(){
      this.changeVerify();
  },
  methods: {
    onLoosen() {
      //console.log(args);
      this.$store
        .dispatch("login/reg", {
          inviteParent: this.inviteParent,
          password: this.password,
          repassword: this.repassword,
          mobile: this.mobile,
          // verifyCode: this.verifyCode,
          uuid: this.uuid
        }) 
        .then(res => {
          setTimeout(() => {
            this.$router.replace("/login");
          }, 1000);
        })
        .catch(e => {});
    },
    onShowDragVerifyImgRotate() {
      this.GetCheckImage();
      this.showDragVerifyImgRotate = true;
    },
    GetCheckImage() {
      getCheckImage({})
        .then(response => {
          this.img = response.result;
        })
        .catch(error => {});
    },
    onClickLeft() {
      this.$router.replace("/login");
    },
    onClickRight() {
      this.$router.push("/main/public/language");
    },
    onChangeArea(value) {
      this.dropTitle = this.option1[value].text.split(" ")[0];
    },
    //刷新验证码
    changeVerify() {
      let verifyUlr = this.verifyUlr + "?id=" + Math.random();
      this.$http
        .get(verifyUlr, { responseType: "arraybuffer" })
        .then(res => {
          // this.verifyUlr = verifyUlr;
          this.uuid = res.headers.uuid;
          let imgURL =
            "data:image/png;base64," +
            btoa(
              new Uint8Array(res.data).reduce(
                (data, byte) => data + String.fromCharCode(byte),
                ""
              )
            );
          this.verifyImage = imgURL;
        })
        .catch(function(error) {});
    },
    downHandler() {
      window.open(this.app_download_url);
    },
    GetDownUrl() {
      getDownUrl({})
        .then(response => {
          this.app_download_url = response.result;
        })
        .catch(error => {});
    },

    //表单提交
    submitHandler(e) {
      const { mobile, password, repassword, inviteParent } = this;
      let field = {
        mobile,
        password,
        repassword,
        inviteParent
      };
      //校验表单
      var objSome = Object.getOwnPropertyNames(field).some(key => {
        let validInfo = this.$validate[
          "valid" + key.charAt(0).toUpperCase() + key.slice(1)
        ](field[key], field);
        if (!validInfo.status) {
          this.$toast({
            message: this.$t(validInfo.msg),
            duration: 1000,
            position: "bottom"
          });
          return true;
        } else {
          return false;
        }
      });

      if (!objSome) {
        this.onLoosen();
      }
    }
  }
};
</script>

<style lang="stylus" rel="stylesheet/stylus">
.regView
  input
    height: 0.8rem
  .van-field__left-icon
    line-height: 0.8rem
    margin-right: 0.36rem
    .van-icon
      font-size: 0.54rem
      color: #4b34c3
  .loginsignup2
    padding-bottom: 0
    padding-top: 0
    position: relative
    .l_1
      padding: 0.8rem 0.45rem 0.8rem
      text-align: left
      color: #fff
      font-size: 2.5em
  .van-icon-success:before
    content: ''
  .login_box
    padding: 0 0 0.8rem
    background: #151d31
    .mobile
      .van-field__label
        justify-content: center
        display: flex
        align-items: center
        width: auto
        .van-dropdown-menu
          .van-dropdown-menu__bar
            height: auto
            background: none
            .van-dropdown-menu__title
              padding: 0 0.21333rem 0 0
      .van-field__control
        margin-left: 0.15rem
      .van-cell
        padding: 0.26667rem 0.2rem 0.26667rem 0.42667rem
      .verify
        margin-top: 0.28rem
    h2
      color: $grayTextColor
</style>
